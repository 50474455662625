<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { nanoid } from 'nanoid';
import type { ListOptions } from '@/types/PropTypes';

const props = withDefaults(
  defineProps<{
    id?: string;
    label: string;
    labelType?: 'default' | 'overlapping' | 'floating';
    options?: ListOptions;
    modelValue?: string | boolean;
    errorMessage?: string;
    highlightError?: boolean;
    block?: boolean;
    boolean?: boolean;
    disabled?: boolean;
    readonly?: boolean;
  }>(),
  {
    id: undefined,
    labelType: 'default',
    options: () => {
      return { yes: 'Yes', no: 'No' };
    },
    modelValue: undefined,
    errorMessage: undefined,
    highlightError: false,
    block: false,
    boolean: false,
    disabled: false,
    readonly: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | boolean | undefined): void;
}>();

const inputId = computed(() => props.id ?? nanoid(8));
const selectedValue = ref<string>();
const listOptions = computed(() =>
  Array.isArray(props.options)
    ? props.options.map((s) => ({ id: s, label: s }))
    : Object.entries(props.options).map(([id, val]) => ({ id, label: typeof val === 'string' ? val : val.label })),
);

watch(
  () => props.modelValue,
  (val) => {
    selectedValue.value = typeof val === 'string' || val === undefined ? val : val ? 'yes' : 'no';
  },
  { immediate: true },
);

function handleChange(event: Event) {
  selectedValue.value = (event.target as HTMLInputElement).value;
  const value = props.boolean ? selectedValue.value === 'yes' : selectedValue.value;
  emit('update:modelValue', value);
}
</script>

<template>
  <div :class="disabled && 'opacity-50'">
    <label
      class="text-sm font-medium text-gray-700 transition-all"
      :class="
        labelType === 'overlapping' || (labelType === 'floating' && selectedValue !== undefined)
          ? '!text-grey-900 !text-xs'
          : labelType === 'floating'
          ? '!text-base'
          : null
      "
      >{{ label }}</label
    >
    <fieldset
      class="mt-3 transition-all"
      :class="labelType === 'overlapping' || (labelType === 'floating' && selectedValue !== undefined) ? '!mt-2' : null"
    >
      <legend class="sr-only">{{ label }}</legend>
      <div class="space-y-4" :class="!block && 'sm:flex sm:items-center sm:space-x-10 sm:space-y-0'">
        <div v-for="item in listOptions" :key="item.id" class="flex items-center">
          <input
            :id="`${inputId}-${item.id}`"
            :data-test-id="`${inputId}-${item.id}`"
            :name="inputId"
            :value="item.id"
            type="radio"
            :checked="item.id === selectedValue"
            class="h-4 w-4 border-gray-300 text-bridgit-royalBlue focus:ring-bridgit-royalBlue"
            :class="errorMessage ? 'focus:!border-rose-500 focus:!ring-rose-500' : null"
            :disabled="disabled || readonly"
            autocomplete="off"
            @change="handleChange"
          />
          <label
            :id="`${inputId}-${item.id}-label`"
            :data-test-id="`${inputId}-${item.id}-label`"
            :for="`${inputId}-${item.id}`"
            class="block pl-3 text-sm text-gray-700"
          >
            {{ item.label }}
          </label>
        </div>
      </div>
    </fieldset>
    <transition :enter-active-class="!highlightError ? 'animate-fadeIn' : ''">
      <p
        v-show="!!errorMessage"
        :id="`${inputId}-error`"
        :data-test-id="`${inputId}-error`"
        class="mt-2 text-sm text-rose-500"
        :class="{ 'animate-shakeX': highlightError }"
      >
        {{ errorMessage }}
      </p>
    </transition>
  </div>
</template>
