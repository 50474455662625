<template>
  <svg
    viewBox="0 0 17 22"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path
      d="M15.8921 8.85201V5.53297C15.8921 4.99353 15.6742 4.47696 15.2879 4.10051L13.1022 1.97086C12.7287 1.60697 12.2279 1.40332 11.7065 1.40332H10.1849H2.5C1.39543 1.40332 0.5 2.29875 0.5 3.40332V18.5622C0.5 19.6624 1.3886 20.556 2.48876 20.5622L13.8809 20.6262C14.9899 20.6324 15.8921 19.7352 15.8921 18.6262V7.96362"
    />
    <path d="M12.5195 1.40332V3.29459C12.5195 4.39916 13.415 5.29459 14.5195 5.29459H15.4596" />
    <path d="M4.3916 9.28442H12.0012" />
    <path d="M3.97314 12.5334H12.199" />
    <path d="M3.97314 16.1895H12.199" />
  </svg>
</template>
