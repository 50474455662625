import type {
  ApplicantAsset,
  ApplicantLiability,
  AssetAccount,
  CompletedLoanApplication,
  CreditCardLiability,
  Employment,
  LoanApplication,
  OtherLiability,
  PropertyAsset,
  SalaryEmployment,
  SelfEmployment,
  VehicleLiability,
} from '@/types';

export const isLoanAppComplete = (loanApp: LoanApplication): loanApp is CompletedLoanApplication => {
  if (!loanApp.userType) {
    console.error('User type is missing - userType', loanApp.userType);
    return false;
  } else if (!loanApp.loanType) {
    console.error('Loan type is missing - loanType', loanApp.loanType);
    return false;
  } else if (!loanApp.loanDetails) {
    console.error('Loan details are missing = loanDetail', loanApp.loanDetails);
    return false;
  } else {
    return true;
  }
};

export const isUnemployed = (e: Employment): e is SelfEmployment => e.type === 'unemployed' || e.type === 'retired';
export const isSelfEmployed = (e: Employment): e is SelfEmployment => e.type === 'self_employed';
export const isSalaried = (e: Employment): e is SalaryEmployment =>
  e.type === 'full_time' || e.type === 'part_time' || e.type === 'casual';
export const isPropertyAsset = (a: ApplicantAsset): a is PropertyAsset => a.type === 'property';
export const isOtherAsset = (a: ApplicantAsset): a is AssetAccount =>
  a.type === 'savings' || a.type === 'shares' || a.type === 'superannuation';

export const isCreditCardLiability = (l: ApplicantLiability): l is CreditCardLiability => l.type === 'card';
export const isVehicleLiability = (l: ApplicantLiability): l is VehicleLiability => l.type === 'vehicle';
export const isOtherLiability = (l: ApplicantLiability): l is OtherLiability => l.type === 'other';
