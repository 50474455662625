<script setup lang="ts">
import type { DischargeRequestProperty } from '@/types/DischargeRequest';
import { ref, computed, watch } from 'vue';
import { useForm } from 'vee-validate';
import { dischargeRequestPropertySchema } from '@/validation';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { useDischargeRequestProcess } from '@/helpers/useDischargeRequestProcess';
import { upperCaseFirstLetter } from '@/helpers/format';
import type { UploadDocument } from '@/types';
import api from '@/api';
import { toast } from '@/helpers/toast';

const props = withDefaults(
  defineProps<{
    modelValue: DischargeRequestProperty;
    reference: DischargeRequestProperty[];
    index: number;
  }>(),
  {},
);

const { loanProperties, type } = useDischargeRequestProcess();

const showEntry = ref(true);
const showModal = ref(false);
const isSaved = ref(true);
const forUploadDocuments = ref<File[]>([]);

const emit = defineEmits<{
  (e: 'update:modelValue', v: DischargeRequestProperty): void;
  (e: 'remove', index?: number): void;
  (e: 'state', index: number, isSaved: boolean): void;
}>();

const { values, meta, submitCount, handleSubmit, resetForm } = useForm<DischargeRequestProperty>({
  validationSchema: dischargeRequestPropertySchema,
  validateOnMount: false,
});

const readonly = computed(() => {
  return type.value === 'review';
});

const propertiesOption = computed(() => {
  if (props.modelValue.repaidByWayOf === 'cash_repayment') {
    return {
      none: '-',
    };
  }
  const excludedPropertyIds: string[] = [];
  const reference = props.reference;
  for (let i = 0; i < reference.length; i++) {
    if (props.index === i) {
      continue;
    }
    if (values.repaidByWayOf === reference[i].repaidByWayOf && reference[i].propertyId) {
      excludedPropertyIds.push(reference[i].propertyId ?? '');
    }
  }
  const result: Record<string, string> = {};
  for (const propertyId in loanProperties.value) {
    const check = excludedPropertyIds.find((item) => item === propertyId);
    if (!check) {
      result[propertyId] = loanProperties.value[propertyId];
    }
  }
  return result;
});

watch(
  () => props.modelValue,
  (data) => {
    if (data.repaidByWayOf === 'cash_repayment') {
      data.propertyId = 'none';
    }
    const values = { ...data };
    resetForm({ values });
    forUploadDocuments.value = data.forUploadDocuments ?? [];

    setTimeout(() => {
      isSaved.value = true;
      updateSaveStatus();
    }, 500);
  },
  { immediate: true, deep: true },
);

watch(
  () => propertiesOption.value,
  () => {
    if (!propertiesOption.value[values.propertyId ?? '']) {
      const newValues = { ...values, propertyId: null, isSaved: false };
      resetForm({ values: newValues });
    }
  },
  { immediate: true, deep: true },
);

watch(
  () => forUploadDocuments.value,
  () => {
    emit('update:modelValue', { ...values, forUploadDocuments: forUploadDocuments.value });
    inputChanged();
  },
  { deep: true },
);

const update = handleSubmit(
  (values) => {
    isSaved.value = true;
    updateSaveStatus();
    emit('update:modelValue', values);
    return true;
  },
  () => false,
);

const removeItem = () => {
  isSaved.value = true;
  updateSaveStatus();
  emit('remove');
};

const inputChanged = () => {
  isSaved.value = false;
  updateSaveStatus();
};

const updateSaveStatus = () => {
  emit('state', props.index, isSaved.value);
};

const fieldsSetup = computed(() => {
  const repaidByWayOf = props.modelValue.repaidByWayOf;
  const result = {
    title: '',
    instruction: {
      visibility: false,
      label: '',
    },
    nameOfInstitutionField: {
      visibility: false,
      label: '',
    },
    dateField: {
      visibility: false,
      label: '',
    },
    amountField: {
      visibility: false,
      label: '',
    },
    documentField: {
      visibility: false,
      label: '',
    },
  };
  if (repaidByWayOf === 'property_sold') {
    result.title = upperCaseFirstLetter(((readonly.value ? '' : 'select') + ' property sold').trim());
    result.instruction = {
      visibility: true,
      label: upperCaseFirstLetter(
        ((readonly.value ? '' : 'select') + ' the property from the list below being sold').trim(),
      ),
    };
    result.nameOfInstitutionField = {
      visibility: true,
      label: 'Name of institution',
    };
    result.dateField = {
      visibility: true,
      label: 'Settlement date',
    };
    result.amountField = {
      visibility: true,
      label: 'Purchase price',
    };
    result.documentField = {
      visibility: true,
      label: 'Copy of the contract of sale',
    };
  } else if (repaidByWayOf === 'refinance') {
    result.title = upperCaseFirstLetter(((readonly.value ? '' : 'select') + ' property refinanced').trim());
    result.instruction = {
      visibility: true,
      label: upperCaseFirstLetter(
        ((readonly.value ? '' : 'select') + ' the property from the list below being refinanced').trim(),
      ),
    };
    result.dateField = {
      visibility: true,
      label: 'Settlement date',
    };
    result.amountField = {
      visibility: true,
      label: 'Purchase price',
    };
    result.documentField = {
      visibility: true,
      label: 'Letter of offer',
    };
  } else if (repaidByWayOf === 'cash_repayment') {
    result.title = 'Cash repayment';
    result.dateField = {
      visibility: true,
      label: 'Repayment date',
    };
    result.amountField = {
      visibility: true,
      label: 'Amount to be repaid',
    };
    result.documentField = {
      visibility: true,
      label: 'Bank Statement confirming Cash Funds',
    };
  }
  return result;
});

const downloadDocument = async (document: UploadDocument) => {
  try {
    const documentResponse = await api.backend.users.document.downloadDocument({ id: document.id });
    if (documentResponse.data.message === 'success') {
      window.open(documentResponse.data.signedUrl);
    } else {
      toast({ type: 'danger', title: 'Error', description: 'Unable to download file' });
    }
  } catch (error) {
    console.error(error);
    toast({ type: 'danger', title: 'Error', description: api.getErrorMsg(error) });
  }
};
</script>
<template>
  <div class="my-8">
    <BxExpander v-model="showEntry" separator>
      <template #header>
        <div class="px-4 py-5 sm:px-6">
          <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <h3 class="text-lg font-medium leading-6 text-gray-900">{{ fieldsSetup.title }}</h3>
          </div>
        </div>
      </template>
      <template #body>
        <div class="p-6">
          <BxField v-if="fieldsSetup.instruction.visibility" v-slot="{ field }" name="propertyId">
            <BxRadio
              v-bind="field"
              :id="`discharge-request-option-property-${props.index}`"
              :options="propertiesOption"
              :label="fieldsSetup.instruction.label"
              label-type="default"
              class="form-control incoming-margin-top"
              block
              :readonly="readonly"
              @input="inputChanged()"
            />
          </BxField>
          <BxField
            v-if="fieldsSetup.dateField.visibility && (values.propertyId || values.repaidByWayOf === 'cash_repayment')"
            v-slot="{ field }"
            name="date"
          >
            <BxDatePicker
              v-bind="field"
              :id="`discharge-request-date-${props.index}`"
              :label="fieldsSetup.dateField.label"
              label-type="floating"
              placeholder="DD/MM/YYYY"
              class="form-control incoming-margin-top"
              :readonly="readonly"
              @date-selected="inputChanged()"
            />
          </BxField>
          <BxField
            v-if="
              fieldsSetup.amountField.visibility && (values.propertyId || values.repaidByWayOf === 'cash_repayment')
            "
            v-slot="{ field }"
            name="amount"
          >
            <BxNumberInput
              v-bind="field"
              :id="`discharge-request-amount-${props.index}`"
              :label="fieldsSetup.amountField.label"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
              :readonly="readonly"
              @input="inputChanged()"
            />
          </BxField>
          <BxField
            v-if="
              fieldsSetup.nameOfInstitutionField.visibility &&
              (values.propertyId || values.repaidByWayOf === 'cash_repayment')
            "
            v-slot="{ field }"
            name="nameOfInstitution"
          >
            <BxInput
              :id="`discharge-request-name-of-institution-${props.index}`"
              class="form-control"
              v-bind="field"
              :label="fieldsSetup.nameOfInstitutionField.label"
              label-type="floating"
              :readonly="readonly"
              @input="inputChanged()"
            />
          </BxField>
          <div v-if="values.propertyId || values.repaidByWayOf === 'cash_repayment'" class="my-4">
            <BxFileUpload
              :id="`discharge-request-document-${props.index}`"
              v-model="forUploadDocuments"
              :readonly="readonly"
              :uploaded-documents="values.documents"
              :label="readonly && !values.documents.length ? '' : fieldsSetup.documentField.label"
              class="form-control incoming-margin-top"
              @file-clicked="downloadDocument"
            />
          </div>
          <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
            Please review the error messages above.
          </BxErrorPanel>
          <div v-if="!readonly" class="mt-10 flex-shrink-0">
            <BxButton variant="secondary" :disabled="isSaved" @click="update()">Save</BxButton>
            <BxButton variant="link" class="ml-4" @click="() => (showModal = true)">Cancel</BxButton>
          </div>
        </div>
      </template> </BxExpander
    ><BxModal
      :open="showModal"
      :icon="TrashIcon"
      title="Remove item"
      confirm-label="Remove"
      cancel-label="Cancel"
      @close="showModal = false"
      @confirm="removeItem()"
    >
      Do you want to remove
      <span class="text-bridgit-green">{{ fieldsSetup.title.toLocaleLowerCase().replace('select', '') }}</span
      >?
    </BxModal>
  </div>
</template>
