import type { operations } from '@/types/schemas/api.JS-3582';
import { POST, setParams } from '../../_http';
type controller = operations['CustomerTasksUploadSupportingDocumentsController_post'];

export type CustomerUploadSupportingDocumentsTaskQuery = controller['parameters']['query'];
export type CustomerUploadSupportingDocumentsTaskBody = controller['requestBody']['content']['application/json'];
export type CustomerUploadSupportingDocumentsTaskResponse =
  controller['responses']['200']['content']['application/json'];
export type UploadSupportingDocumentsFiles = controller['requestBody']['content']['application/json']['files'];
export type UploadSupportingDocumentsFile = controller['requestBody']['content']['application/json']['files'][0];
export type UploadSupportingDocumentsFileContentType =
  controller['requestBody']['content']['application/json']['files']['0']['contentType'];

export const uploadSupportingDocuments = POST(
  '/users/tasks/upload-supporting-documents',
  setParams<CustomerUploadSupportingDocumentsTaskQuery>,
)<CustomerUploadSupportingDocumentsTaskBody, CustomerUploadSupportingDocumentsTaskResponse>;
