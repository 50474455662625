import type { TaskDto } from '@/api/backend/users/task';
import {
  DischargeRequestStep,
  DischargeRequestStepNames,
  DischargeRequestProcessSteps,
  StepStatus,
  type ActionType,
  type AppUser,
  type DischargeRequestProcessStatus,
  type DischargeRequestProcessStepEnum,
  type LoanApplication,
} from '@/types';
import { assign, createMachine } from 'xstate';

export interface DischargeRequestMachineContext {
  loanApp: LoanApplication;
  from?: string;
  task: TaskDto; // current task
  originTaskId: string | null;
  type: 'submit' | 'review';
  authorisedUser: Record<string, string>;
  status: DischargeRequestProcessStatus;
  current: DischargeRequestProcessStepEnum;
}

export const initDischargeRequestMachine = (params?: {
  actionType: ActionType;
  userType: AppUser;
  application: LoanApplication;
  task: TaskDto;
  originTaskId: string | null;
  type: 'submit' | 'review';
}): DischargeRequestMachineContext => {
  return {
    loanApp: params?.application || <LoanApplication>{},
    task: params?.task ?? null,
    originTaskId: params?.originTaskId ?? null,
    type: params?.type ?? 'submit',
    authorisedUser: {},
    status: Object.fromEntries(DischargeRequestStepNames.map((step) => [step])) as DischargeRequestProcessStatus,
    current: DischargeRequestStep.DischargeRequest,
  };
};

export type DischargeRequestMachineEvent =
  | { type: 'NEXT'; loanApp: LoanApplication }
  | { type: 'UPDATE'; loanApp: LoanApplication }
  | { type: 'BACK' }
  | { type: 'READY' }
  | { type: 'GOTO'; step: DischargeRequestProcessStepEnum }
  | { type: 'RELOAD'; state: DischargeRequestMachineContext }
  | { type: 'RESTART' };

export const loanAppDischargeRequestMachine = createMachine<
  DischargeRequestMachineContext,
  DischargeRequestMachineEvent
>(
  {
    id: 'loanApplicationDischargeRequest',
    predictableActionArguments: true,
    schema: {
      context: {} as DischargeRequestMachineContext,
    },
    context: initDischargeRequestMachine(),
    initial: 'start',
    states: {
      start: {
        on: {
          NEXT: {
            target: DischargeRequestStep.DischargeRequest,
          },
        },
      },
      [DischargeRequestStep.DischargeRequest]: {
        on: {
          NEXT: [
            {
              target: DischargeRequestStep.RepresentativeDetails,
              actions: 'saveData',
            },
          ],
        },
      },
      [DischargeRequestStep.RepresentativeDetails]: {
        on: {
          NEXT: [
            {
              target: DischargeRequestStep.SubmitDischargeRequest,
              actions: 'saveData',
            },
          ],
        },
      },
      [DischargeRequestStep.SubmitDischargeRequest]: {
        on: {
          NEXT: {
            target: DischargeRequestStep.Finished,
            actions: 'saveData',
          },
        },
      },
      [DischargeRequestStep.Finished]: {
        on: {
          NEXT: {
            target: DischargeRequestStep.Finished,
            actions: 'resetLoanApplication',
          },
        },
      },
      prevStage: {
        always: [...DischargeRequestStepNames]
          .sort((a, b) => DischargeRequestProcessSteps[b].order - DischargeRequestProcessSteps[a].order)
          .map((step) => ({
            target: step,
            cond: (ctx) =>
              DischargeRequestProcessSteps[ctx.current]?.order > DischargeRequestProcessSteps[step].order &&
              ctx.status[step] === StepStatus.Completed,
          })),
      },
      gotoReady: {
        always: DischargeRequestStepNames.map((step) => ({
          target: step,
          cond: (ctx) => ctx.status[step] === StepStatus.Ready,
        })),
      },
    },
    on: {
      BACK: {
        target: 'prevStage',
        cond: (ctx) => ctx.current !== DischargeRequestStep.DischargeRequest,
      },
      READY: {
        target: 'gotoReady',
      },
      UPDATE: {
        actions: 'saveData',
      },
      GOTO: DischargeRequestStepNames.map((step) => ({
        target: step,
        cond: (ctx, evt) => evt.step === step,
      })),
      RELOAD: {
        target: 'gotoReady',
        actions: 'updateContext',
      },
      RESTART: {
        target: DischargeRequestStep.DischargeRequest,
        actions: 'resetLoanApplication',
      },
    },
  },
  {
    actions: {
      saveData: assign((context, event) => {
        return event.type === 'NEXT' || event.type === 'UPDATE'
          ? {
              loanApp: event.loanApp,
              status: Object.fromEntries(
                Object.entries(context.status).map(([step, status]) => [
                  step,
                  step === context.current
                    ? StepStatus.Completed
                    : DischargeRequestProcessSteps[step as DischargeRequestProcessStepEnum].order <
                      DischargeRequestProcessSteps[context.current].order
                    ? status
                    : StepStatus.Incomplete,
                ]),
              ) as DischargeRequestProcessStatus,
            }
          : {};
      }),
      updateContext: assign((context, event) => (event.type === 'RELOAD' ? event.state : {})),
    },
    guards: {
      hasNoBroker: (ctx) => ctx.loanApp.userType !== 'Broker',
    },
  },
);
