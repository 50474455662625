<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
      fill="#020038"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7007 11.2866C21.0947 11.6735 21.1004 12.3067 20.7134 12.7007L13.8384 19.7007C13.6504 19.8922 13.3933 20 13.125 20C12.8567 20 12.5996 19.8922 12.4116 19.7007L9.28655 16.5189C8.89956 16.1249 8.90527 15.4917 9.29929 15.1047C9.69332 14.7177 10.3265 14.7234 10.7134 15.1175L13.125 17.5729L19.2866 11.2993C19.6735 10.9053 20.3067 10.8996 20.7007 11.2866Z"
      fill="white"
    />
  </svg>
</template>
