import api from '@/api';
import { mapToApi } from '@/mappers';
import type { AsyncResult, LoanApplication } from '@/types';
import { computed, ref } from 'vue';
import type { DischargeRequest } from '@/types/DischargeRequest';
import type { UploadSupportingDocumentsFileContentType, UploadSupportingDocumentsFile } from '@/api/backend/users/task';
import { uploadFileToS3 } from './fileUpload';
import { formatCurrency } from './format';

type ForUpload = UploadSupportingDocumentsFile & {
  file: File;
};

export function useDischargeRequestApi() {
  const isProcessing = ref(0);
  const currentProgress = ref('');

  const submitDischargeRequest = async (data: {
    applicationId: string;
    loanApplication: LoanApplication;
    taskId: string;
    dischargeRequest: DischargeRequest;
    loanProperties: Record<string, string>;
  }): AsyncResult<string> => {
    const { applicationId, taskId, dischargeRequest, loanProperties, loanApplication } = data;
    try {
      isProcessing.value++;
      const files: ForUpload[] = [];
      for (const property of dischargeRequest.properties) {
        if (!property.forUploadDocuments.length) {
          continue;
        }
        let fileNamePrefix = '';
        const propertyAddress = loanProperties[property.propertyId ?? ''];
        if (property.repaidByWayOf === 'property_sold') {
          fileNamePrefix = `[COS] - [${propertyAddress ?? ''}]`;
        } else if (property.repaidByWayOf === 'refinance') {
          fileNamePrefix = `[Letter of offer] - [${propertyAddress ?? ''}]`;
        } else if (property.repaidByWayOf === 'cash_repayment') {
          fileNamePrefix = `Proof of funds - [${formatCurrency(property.amount ?? 0)}]`;
        }
        for (const document of property.forUploadDocuments) {
          const file = new File([document], `${fileNamePrefix} - ${document.name}`, {
            type: document.type,
          });
          files.push({
            fileName: file.name,
            contentType: file.type as UploadSupportingDocumentsFileContentType,
            contentLength: file.size,
            file,
          });
        }
      }
      if (files.length) {
        currentProgress.value = 'Preparing documents for upload...';
        const uploadData = {
          completeTask: false,
          files,
        };
        const uploadResponse =
          loanApplication.userType === 'Broker'
            ? await api.backend.brokers.task.uploadSupportingDocuments({ taskId }, uploadData)
            : await api.backend.users.task.uploadSupportingDocuments({ taskId }, uploadData);
        if (uploadResponse.data.message !== 'success') {
          return { success: false, error: uploadResponse.data.message };
        }
        const signedUrls = uploadResponse.data?.signedUrls ?? [];
        let index = 0;
        for (const signedUrl of signedUrls) {
          const file = files.find((item) => item.fileName === signedUrl.originalFileName);
          if (!file) {
            continue;
          }
          currentProgress.value = `Uploading documents: ${index + 1} of ${files.length}`;
          await uploadFileToS3(signedUrl.signedUrl, file.file);
          index++;
        }
      }
      currentProgress.value = 'Submitting discharge request...';
      const payload = mapToApi.dischargeRequest(dischargeRequest);
      const response =
        loanApplication.userType === 'Broker'
          ? await api.backend.brokers.settlement.submitDischargeRequest({ applicationId, taskId }, payload)
          : await api.backend.users.settlement.submitDischargeRequest({ applicationId, taskId }, payload);
      return { success: true, value: response.data.message };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
      currentProgress.value = '';
    }
  };

  const signDischargeRequest = async (data: { applicationId: string; taskId: string }): AsyncResult<string> => {
    const { applicationId, taskId } = data;
    isProcessing.value++;
    try {
      currentProgress.value = 'Submitting signed discharge request...';
      const response = await api.backend.users.settlement.signDischargeRequest({ applicationId }, { taskId });
      return { success: true, value: response.data.message };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
      currentProgress.value = '';
    }
  };

  return {
    isLoading: computed(() => isProcessing.value > 0),
    currentProgress,
    signDischargeRequest,
    submitDischargeRequest,
  };
}
