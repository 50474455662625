import type { operations } from '@/types/schemas/api.JS-3582';
import { POST, setParams } from '../../_http';
type controller = operations['CustomerSignDischargeRequestController_post'];

export type CustomerSignDischargeRequestQuery = controller['parameters']['query'];
export type CustomerSignDischargeRequestBody = controller['requestBody']['content']['application/json'];
export type CustomerSignDischargeRequestResponse = controller['responses']['200']['content']['application/json'];

export const signDischargeRequest = POST(
  '/users/discharge-requests/sign-discharge-request',
  setParams<CustomerSignDischargeRequestQuery>,
)<CustomerSignDischargeRequestBody, CustomerSignDischargeRequestResponse>;
