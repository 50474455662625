import type { operations } from '@/types/schemas/api.JS-3582';
import { GET, setParams } from '../../_http';
type controller = operations['CustomerDocumentsDownloadDocumentController_get'];

export type CustomerDownloadDocumentQuery = controller['parameters']['query'];
export type CustomerDownloadDocumentResponse = controller['responses']['200']['content']['application/json'];

export const downloadDocument = GET(
  '/users/documents/download-document',
  setParams<CustomerDownloadDocumentQuery>,
)<CustomerDownloadDocumentResponse>;
