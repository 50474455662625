import type { IconName } from '@/helpers/icons';
import type { ActionType } from 'xstate';

export interface ProcessStepInfo {
  label: string;
  icon: IconName;
  order: number;
  actions: ActionType[];
  route?: string;
}

export const enum StepStatus {
  Incomplete,
  Ready,
  Completed,
  Skipped,
}

export const CustomerApplicationProcessStep = {
  SelectCustomerType: 'selectCustomerType',
  SelectLoanType: 'selectLoanType',
  IncomingProperties: 'incomingProperties',
  OutgoingProperties: 'outgoingProperties',
  LoanDetails: 'loanDetails',
  ApplicantDetails: 'applicantDetails',
  PropertyOwnership: 'propertyOwnership',
  Summary: 'summary',
  Income: 'income',
  SummaryIncome: 'summaryIncome',
  Expenses: 'expenses',
  Assets: 'assets',
  VerifyIdentity: 'verification',
  SubmitApplication: 'submitApplication',
  Finished: 'completed',
} as const;

export const CustomerApplicationProcessStepNames = [
  'selectCustomerType',
  'selectLoanType',
  'incomingProperties',
  'outgoingProperties',
  'loanDetails',
  'applicantDetails',
  'propertyOwnership',
  'summary',
  'income',
  'expenses',
  'assets',
  'verification',
  'submitApplication',
  'completed',
] as const;

export type CustomerApplicationProcessStepEnum = (typeof CustomerApplicationProcessStepNames)[number];

export const BrokerProcessStep = {
  SelectLoanType: 'brokerSelectLoanType',
  LoanPurpose: 'brokerLoanPurpose',
  IncomingProperties: 'brokerIncomingProperties',
  OutgoingProperties: 'brokerOutgoingProperties',
  LoanDetails: 'brokerLoanDetails',
  Income: 'brokerIncome',
  Expenses: 'brokerExpenses',
  Assets: 'brokerAssets',
  ApplicantDetails: 'brokerApplicantDetails',
  PropertyOwnership: 'brokerPropertyOwnership',
  SubmitApplication: 'brokerSubmitApplication',
  Finished: 'completed',
} as const;

export const BrokerProcessStepNames = [
  'brokerSelectLoanType',
  'brokerLoanPurpose',
  'brokerIncomingProperties',
  'brokerOutgoingProperties',
  'brokerLoanDetails',
  'brokerApplicantDetails',
  'brokerPropertyOwnership',
  'brokerIncome',
  'brokerExpenses',
  'brokerAssets',
  'brokerSubmitApplication',
  'completed',
] as const;

export const DeclarationProcessStep = {
  Summary: 'declarationSummary',
  Income: 'declarationIncome',
  Expenses: 'declarationExpenses',
  Assets: 'declarationAssets',
  VerifyIdentity: 'declarationVerification',
  SubmitApplication: 'declarationSubmitApplication',
  Finished: 'completed',
} as const;

export const DeclarationProcessStepNames = [
  'declarationSummary',
  'declarationIncome',
  'declarationExpenses',
  'declarationAssets',
  'declarationVerification',
  'declarationSubmitApplication',
  'completed',
] as const;

export const DischargeRequestStep = {
  DischargeRequest: 'dischargeRequest',
  RepresentativeDetails: 'representativeDetails',
  SubmitDischargeRequest: 'submitDischargeRequest',
  Finished: 'completed',
} as const;

export const DischargeRequestStepNames = [
  'dischargeRequest',
  'representativeDetails',
  'submitDischargeRequest',
  'completed',
] as const;

export const DischargeRequestProcessSteps: Record<DischargeRequestProcessStepEnum, ProcessStepInfo> = {
  dischargeRequest: {
    label: 'Discharge request',
    icon: 'accept',
    order: 20,
    actions: ['updateExisting'],
    route: 'dischargeRequest',
  },
  representativeDetails: {
    label: 'Representative details',
    icon: 'applicant',
    order: 30,
    actions: ['createNew', 'updateExisting'],
    route: 'representativeDetails',
  },
  submitDischargeRequest: {
    label: 'Submit request',
    icon: 'accept',
    order: 40,
    actions: ['createNew', 'updateExisting'],
    route: 'submitDischargeRequest',
  },
  completed: {
    label: 'Complete',
    icon: 'circleTick',
    order: 50,
    actions: ['createNew', 'updateExisting'],
    route: 'completed',
  },
};

export const RequestIncomeExpenseProcessStepNames = [
  'requestSummary',
  'requestIncome',
  'requestExpenses',
  'requestAssets',
  'requestSubmitApplication',
  'completed',
] as const;

export const RequestIncomeExpenseProcessStep = {
  Summary: 'requestSummary',
  Income: 'requestIncome',
  Expenses: 'requestExpenses',
  Assets: 'requestAssets',
  VerifyIdentity: 'requestVerification',
  SubmitApplication: 'requestSubmitApplication',
  Finished: 'completed',
} as const;

export const RequestIncomeExpenseProcessSteps: Record<RequestIncomeExpenseProcessStepEnum, ProcessStepInfo> = {
  requestSummary: {
    label: 'Loan summary',
    icon: 'accept',
    order: 20,
    actions: ['updateExisting'],
    route: 'requestSummary',
  },
  requestIncome: {
    label: 'Income',
    icon: 'income',
    order: 30,
    actions: ['createNew', 'updateExisting', 'saveData'],
    route: 'requestIncome',
  },
  requestExpenses: {
    label: 'Expenses',
    icon: 'expense',
    order: 40,
    actions: ['createNew', 'updateExisting'],
    route: 'requestExpenses',
  },
  requestAssets: {
    label: 'Assets & Liabilities',
    icon: 'liability',
    order: 50,
    actions: ['createNew', 'updateExisting'],
    route: 'requestAssets',
  },
  requestSubmitApplication: {
    label: 'Submit application',
    icon: 'accept',
    order: 60,
    actions: ['createNew', 'updateExisting'],
    route: 'requestSubmitApplication',
  },
  completed: {
    label: 'Complete',
    icon: 'circleTick',
    order: 70,
    actions: ['createNew', 'updateExisting'],
    route: 'completed',
  },
};

export const DeclarationProcessSteps: Record<DeclarationProcessStepEnum, ProcessStepInfo> = {
  declarationSummary: {
    label: 'Loan summary',
    icon: 'accept',
    order: 20,
    actions: ['updateExisting'],
    route: 'declarationSummary',
  },
  declarationIncome: {
    label: 'Income',
    icon: 'income',
    order: 30,
    actions: ['createNew', 'updateExisting', 'saveData'],
    route: 'declarationIncome',
  },
  declarationExpenses: {
    label: 'Expenses',
    icon: 'expense',
    order: 40,
    actions: ['createNew', 'updateExisting'],
    route: 'declarationExpenses',
  },
  declarationAssets: {
    label: 'Assets & Liabilities',
    icon: 'liability',
    order: 50,
    actions: ['createNew', 'updateExisting'],
    route: 'declarationAssets',
  },
  declarationVerification: {
    label: 'Verify identity',
    icon: 'proof',
    order: 60,
    actions: ['createNew', 'updateExisting'],
    route: 'declarationVerification',
  },
  declarationSubmitApplication: {
    label: 'Submit application',
    icon: 'accept',
    order: 70,
    actions: ['createNew', 'updateExisting'],
    route: 'declarationSubmitApplication',
  },
  completed: {
    label: 'Complete',
    icon: 'circleTick',
    order: 80,
    actions: ['createNew', 'updateExisting'],
    route: 'completed',
  },
};

export const BrokerApplicationProcessSteps: Record<BrokerProcessStepEnum, ProcessStepInfo> = {
  brokerSelectLoanType: {
    label: 'Start application',
    icon: 'accept',
    order: 20,
    actions: ['createNew'],
    route: 'brokerSelectLoanType',
  },
  brokerLoanPurpose: {
    label: 'Loan Purpose',
    icon: 'application',
    order: 30,
    actions: ['createNew'],
    route: 'brokerLoanPurpose',
  },
  brokerIncomingProperties: {
    label: 'Buying',
    icon: 'houseInfo',
    order: 40,
    actions: ['createNew'],
    route: 'brokerIncomingProperties',
  },
  brokerOutgoingProperties: {
    label: 'Selling',
    icon: 'selling',
    order: 50,
    actions: ['createNew'],
    route: 'brokerOutgoingProperties',
  },
  brokerLoanDetails: {
    label: 'Loan details',
    icon: 'loan',
    order: 60,
    actions: ['createNew'],
    route: 'brokerLoanDetails',
  },
  brokerApplicantDetails: {
    label: 'Applicant details',
    icon: 'applicant',
    order: 70,
    actions: ['createNew'],
    route: 'brokerApplicantDetails',
  },
  brokerPropertyOwnership: {
    label: 'Property ownership',
    icon: 'applicant',
    order: 80,
    actions: ['createNew'],
    route: 'brokerPropertyOwnership',
  },
  brokerIncome: {
    label: 'Income',
    icon: 'income',
    order: 90,
    actions: ['createNew', 'updateExisting'],
    route: 'brokerIncome',
  },
  brokerExpenses: {
    label: 'Expenses',
    icon: 'expense',
    order: 100,
    actions: ['createNew', 'updateExisting'],
    route: 'brokerExpenses',
  },
  brokerAssets: {
    label: 'Assets & Liabilities',
    icon: 'liability',
    order: 110,
    actions: ['createNew', 'updateExisting'],
    route: 'brokerAssets',
  },
  brokerSubmitApplication: {
    label: 'Submit application',
    icon: 'accept',
    order: 120,
    actions: ['createNew', 'updateExisting'],
    route: 'brokerSubmitApplication',
  },
  completed: {
    label: 'Complete',
    icon: 'circleTick',
    order: 130,
    actions: ['createNew', 'updateExisting'],
    route: 'completed',
  },
};

export const CustomerApplicationProcessSteps: Record<CustomerApplicationProcessStepEnum, ProcessStepInfo> = {
  selectCustomerType: {
    label: 'Broker or Customer',
    icon: 'accept',
    order: 10,
    actions: [],
  },
  selectLoanType: {
    label: 'Start application',
    icon: 'accept',
    order: 20,
    actions: ['createNew'],
    route: 'selectLoanType',
  },
  incomingProperties: {
    label: 'Buying',
    icon: 'houseInfo',
    order: 30,
    actions: ['createNew'],
    route: 'incomingProperties',
  },
  outgoingProperties: {
    label: 'Selling',
    icon: 'selling',
    order: 40,
    actions: ['createNew'],
    route: 'outgoingProperties',
  },
  loanDetails: {
    label: 'Loan details',
    icon: 'loan',
    order: 50,
    actions: ['createNew'],
    route: 'loanDetails',
  },
  applicantDetails: {
    label: 'Applicant details',
    icon: 'applicant',
    order: 60,
    actions: ['createNew'],
    route: 'applicantDetails',
  },
  propertyOwnership: {
    label: 'Property ownership',
    icon: 'applicant',
    order: 70,
    actions: ['createNew'],
    route: 'propertyOwnership',
  },
  summary: {
    label: 'Review summary',
    icon: 'accept',
    order: 80,
    actions: ['updateExisting'],
    route: 'summary',
  },
  income: {
    label: 'Income',
    icon: 'income',
    order: 90,
    actions: ['createNew', 'updateExisting'],
    route: 'income',
  },
  expenses: {
    label: 'Expenses',
    icon: 'expense',
    order: 100,
    actions: ['createNew', 'updateExisting'],
    route: 'expenses',
  },
  assets: {
    label: 'Assets & Liabilities',
    icon: 'liability',
    order: 110,
    actions: ['createNew', 'updateExisting'],
    route: 'assets',
  },
  verification: {
    label: 'Verify identity',
    icon: 'proof',
    order: 120,
    actions: ['createNew'],
    route: 'verification',
  },
  submitApplication: {
    label: 'Submit application',
    icon: 'accept',
    order: 130,
    actions: ['createNew', 'updateExisting'],
    route: 'submitApplication',
  },
  completed: {
    label: 'Complete',
    icon: 'circleTick',
    order: 140,
    actions: ['createNew', 'updateExisting'],
    route: 'completed',
  },
};

export type DeclarationProcessStepEnum = (typeof DeclarationProcessStepNames)[number];
export type RequestIncomeExpenseProcessStepEnum = (typeof RequestIncomeExpenseProcessStepNames)[number];
export type DischargeRequestProcessStepEnum = (typeof DischargeRequestStepNames)[number];

export type ProcessStatus = { [K in CustomerApplicationProcessStepEnum]: StepStatus };
export type BrokerProcessStatus = { [K in BrokerProcessStepEnum]: StepStatus };
export type DeclarationProcessStatus = { [K in DeclarationProcessStepEnum]: StepStatus };
export type RequestIncomeExpenseProcessStatus = { [K in RequestIncomeExpenseProcessStepEnum]: StepStatus };
export type DischargeRequestProcessStatus = { [K in DischargeRequestProcessStepEnum]: StepStatus };

export type BrokerProcessStepEnum = (typeof BrokerProcessStepNames)[number];

export interface CustomerApplicationProcessStepStatus extends ProcessStepInfo {
  id: CustomerApplicationProcessStepEnum;
  status: StepStatus;
}
export interface BrokerApplicationProcessStepStatus extends ProcessStepInfo {
  id: BrokerProcessStepEnum;
  status: StepStatus;
}
export interface DeclarationProcessStepStatus extends ProcessStepInfo {
  id: DeclarationProcessStepEnum;
  status: StepStatus;
}
export interface RequestIncomeExpenseProcessStepStatus extends ProcessStepInfo {
  id: RequestIncomeExpenseProcessStepEnum;
  status: StepStatus;
}

export interface DischargeRequestProcessStepStatus extends ProcessStepInfo {
  id: DischargeRequestProcessStepEnum;
  status: StepStatus;
}
