import type { DischargeRequestProperty } from '@/types/DischargeRequest';
import { yupNumber, yupObject, yupString } from '../../yup';

export const dischargeRequestPropertySchema = yupObject<DischargeRequestProperty>({
  repaidByWayOf: yupString().required().label('Repaid way of'),
  propertyId: yupString()
    .when('repaidByWayOf', ([repaidByWayOf], schema) =>
      ['property_sold', 'refinance'].includes(repaidByWayOf) ? schema.required() : schema,
    )
    .label('Property'),
  date: yupString().required().label('Date'),
  nameOfInstitution: yupString()
    .when('repaidByWayOf', ([repaidByWayOf], schema) =>
      ['property_sold'].includes(repaidByWayOf) ? schema.required() : schema,
    )
    .label('Name of institution'),
  amount: yupNumber().required().min(0).label('Amount'),
});
