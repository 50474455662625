import type { operations } from '@/types/schemas/api.JS-3582';
import { POST, setParams } from '../../_http';
type controller = operations['CustomerSubmitDischargeRequestController_post'];

export type CustomerSubmitDischargeRequestQuery = controller['parameters']['query'];
export type CustomerSubmitDischargeRequestBody = controller['requestBody']['content']['application/json'];
export type CustomerSubmitDischargeRequestResponse = controller['responses']['200']['content']['application/json'];
export type SubmitDischargeRequestSolicitorDto = controller['requestBody']['content']['application/json']['solicitor'];

export const submitDischargeRequest = POST(
  '/users/discharge-requests/submit-discharge-request',
  setParams<CustomerSubmitDischargeRequestQuery>,
)<CustomerSubmitDischargeRequestBody, CustomerSubmitDischargeRequestResponse>;
