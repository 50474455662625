import type { CustomerTaskResult } from '@/api/backend/users/task';
import type { Address, LoanApplication, PropertyId, UploadDocument } from '@/types';
import type { DischargeRequest, RepaidByWayOf } from '@/types/DischargeRequest';
import { address } from './address';
import { formatAddress } from '@/helpers/format';

export const mapDischargeRequest = (application: LoanApplication, task: CustomerTaskResult): DischargeRequest => {
  const result: DischargeRequest = {
    representativeDetails: {
      firstName: application.solicitor?.firstName ?? '',
      lastName: application.solicitor?.lastName ?? '',
      company: application.solicitor?.company ?? '',
      email: application.solicitor?.email ?? '',
      mobilePhoneNumber: application.solicitor?.mobilePhoneNumber ?? '',
      address: application.solicitor ? address(application.solicitor) : null,
    },
    properties: [],
  };

  const details = (task?.applicationSettlement?.applicationSettlementDetails ?? []).filter(
    (item) => item.type === 'submission',
  );
  if (details.find((item) => item.detailName === 'is_cash_payment' && item.type === 'submission')) {
    const documents = (task?.documents ?? [])
      .filter((item) => (item?.originalFileName ?? '').includes('Proof of funds - ['))
      .map((item) => {
        return {
          id: item.id,
          s3FilePath: item.originalFileName,
        };
      });
    result.properties.push({
      propertyId: '-',
      repaidByWayOf: 'cash_repayment',
      nameOfInstitution: details.find((item) => item.detailName === 'cash_payment_institution_name')?.valueString ?? '',
      date: details.find((item) => item.detailName === 'cash_payment_settlement_date')?.valueDate ?? '',
      amount: details.find((item) => item.detailName === 'cash_payment_amount')?.valueNumeric ?? 0,
      documents,
      forUploadDocuments: [],
    });
  }

  const properties = [...application.incomingProperties, ...application.outgoingProperties].reduce((obj, item) => {
    obj[item.id] = formatAddress(item.address as Address);
    return obj;
  }, {} as Record<PropertyId, string>);

  for (const property of task?.applicationSettlement?.applicationsSettlementProperties ?? []) {
    for (const detail of property.applicationsSettlementPropertyDetails ?? []) {
      if (detail.type === 'review') {
        continue;
      }

      let documents: UploadDocument[] = [];
      const propertyId = (property.incomingPropertyId || property.outgoingPropertyId) ?? '';
      const propertyAddress = properties[propertyId];
      if (propertyAddress) {
        let keyword: string | null = null;
        if (detail.detailName === 'property_sold') {
          keyword = `[COS] - [${propertyAddress}]`;
        } else if (detail.detailName === 'refinance') {
          keyword = `[Letter of offer] - [${propertyAddress}]`;
        }
        if (keyword) {
          documents = (task?.documents ?? [])
            .filter((item) => (item?.originalFileName ?? '').includes(keyword ?? ''))
            .map((item) => {
              return {
                id: item.id,
                s3FilePath: item.originalFileName,
              };
            });
        }
      }

      result.properties.push({
        propertyId,
        repaidByWayOf: detail.detailName as RepaidByWayOf,
        nameOfInstitution: detail.institutionName,
        date: detail.settlementDate,
        amount: detail.amount,
        documents,
        forUploadDocuments: [],
      });
    }
  }

  return result;
};
