import { yupAddress, yupObject, yupString } from '../../yup';
import type { SolicitorDto } from '@/api/backend/users/applications';

export const solicitorSchema = yupObject<SolicitorDto>({
  firstName: yupString().required().label('First name'),
  lastName: yupString().required().label('Last name'),
  mobilePhoneNumber: yupString()
    .matches(/^(\+\d{11,15}|04\d{8})$/, '${path} must be valid')
    .required()
    .label('Mobile number'),
  email: yupString().email().required().label('Email'),
  company: yupString().required().label('Company'),
  address: yupAddress().required().label('Property address'),
});
