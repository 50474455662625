import type { CustomerSubmitDischargeRequestBody, SettlementPropertyDetailName } from '@/api/backend/users/settlements';
import type { DischargeRequest } from '@/types/DischargeRequest';
import { mapToApi } from '@/mappers';

export const dischargeRequest = (data: DischargeRequest): CustomerSubmitDischargeRequestBody => {
  const result: CustomerSubmitDischargeRequestBody = {
    applicationSettlementDetails: [],
    applicationSettlementProperties: [],
    solicitor: {
      company: data.representativeDetails.company,
      firstName: data.representativeDetails.firstName,
      lastName: data.representativeDetails.lastName,
      mobilePhoneNumber: data.representativeDetails.mobilePhoneNumber,
      email: data.representativeDetails.email,
      line1: data.representativeDetails.address?.addressLine,
      line2: data.representativeDetails.address?.addressLine2 ?? '',
      city: data.representativeDetails.address?.locality,
      country: 'au',
      postCode: data.representativeDetails.address?.postcode,
      state: mapToApi.australianState(data.representativeDetails.address?.state),
    },
  };
  const cashRepayment = data.properties.find((item) => item.repaidByWayOf === 'cash_repayment');
  if (cashRepayment) {
    result.applicationSettlementDetails.push(
      {
        detailName: 'is_cash_payment',
        valueBoolean: true,
      },
      {
        detailName: 'cash_payment_amount',
        valueNumeric: cashRepayment.amount ?? 0,
      },
      {
        detailName: 'cash_payment_institution_name',
        valueString: cashRepayment.nameOfInstitution ?? '',
      },
      {
        detailName: 'cash_payment_settlement_date',
        valueDate: new Date(cashRepayment.date ?? '').toISOString(),
      },
    );
  }
  for (const property of data.properties) {
    if (!property.propertyId || property.repaidByWayOf === 'cash_repayment') {
      continue;
    }
    result.applicationSettlementProperties.push({
      propertyId: property.propertyId ?? '',
      applicationsSettlementPropertyDetails: [
        {
          detailName: property.repaidByWayOf as SettlementPropertyDetailName,
          institutionName: property.nameOfInstitution ?? '',
          amount: property.amount ?? 0,
          settlementDate: new Date(property.date ?? '').toISOString(),
        },
      ],
    });
  }
  return result;
};
