import type { operations } from '@/types/schemas/api.JS-3582';
import { POST, setParams } from '../../_http';
type controller = operations['BrokerSubmitDischargeRequestController_post'];

export type BrokerSubmitDischargeRequestQuery = controller['parameters']['query'];
export type BrokerSubmitDischargeRequestBody = controller['requestBody']['content']['application/json'];
export type BrokerSubmitDischargeRequestResponse = controller['responses']['200']['content']['application/json'];

export const submitDischargeRequest = POST(
  '/brokers/discharge-requests/submit-discharge-request',
  setParams<BrokerSubmitDischargeRequestQuery>,
)<BrokerSubmitDischargeRequestBody, BrokerSubmitDischargeRequestResponse>;
