<script setup lang="ts">
import { useDischargeRequestProcess } from '@/helpers/useDischargeRequestProcess';
import { PlusCircleIcon } from '@heroicons/vue/20/solid';
import PropertyForm from './modules/Properties/PropertyForm.vue';
import { ref, watch, computed } from 'vue';
import type { DischargeRequest, DischargeRequestProperty, RepaidByWayOf } from '@/types/DischargeRequest';

const { saveAndGoNext, dischargeRequest, loanProperties, type } = useDischargeRequestProcess();

const properties = ref<DischargeRequestProperty[]>([]);
const unsavedItems = ref<number[]>([]);
const errorMessage = ref('');

watch(
  () => dischargeRequest.value?.properties,
  () => {
    properties.value = dischargeRequest.value?.properties ?? [];
  },
  { immediate: true, deep: true },
);

const newProperty = (type: RepaidByWayOf) => {
  properties.value.push({
    propertyId: null,
    repaidByWayOf: type,
    date: '',
    nameOfInstitution: '',
    amount: 0,
    forUploadDocuments: [],
    documents: [],
  });
  unsavedItems.value.push(properties.value.length - 1);
};

const removeProperty = (index: number) => {
  if (index > -1) {
    properties.value.splice(index, 1);
  }
};

const itemState = (index: number, isSaved: boolean) => {
  errorMessage.value = '';
  const checkIndex = unsavedItems.value.findIndex((item) => item === index);
  if (checkIndex > -1) {
    if (isSaved) {
      unsavedItems.value.splice(checkIndex, 1);
    }
  } else {
    if (!isSaved) {
      unsavedItems.value.push(index);
    }
  }
};

const saveAndContinue = async () => {
  errorMessage.value = '';
  if (unsavedItems.value.length && type.value === 'submit') {
    errorMessage.value = 'You have unsaved item(s).';
    return;
  }
  saveAndGoNext({
    dischargeRequest: { ...dischargeRequest.value, properties: properties.value } as DischargeRequest,
  });
};

const checkDisableAddProperty = (type: RepaidByWayOf) => {
  if (
    properties.value.filter((item) => item.repaidByWayOf === type).length === Object.keys(loanProperties.value).length
  ) {
    return true;
  }
  let result = false;
  for (const propertyId in loanProperties.value) {
    result = properties.value.some((item) => item.repaidByWayOf === type && item.propertyId === propertyId);
  }
  return result;
};

const disableAddNewPropertySold = computed(() => {
  return checkDisableAddProperty('property_sold');
});

const disableAddNewPropertyRefinanced = computed(() => {
  return checkDisableAddProperty('refinance');
});

const disableAddNewCashRepayment = computed(() => {
  return properties.value.find((item) => item.repaidByWayOf === 'cash_repayment') !== undefined;
});
</script>

<template>
  <div>
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">How is the loan being <BxHighlight>discharged</BxHighlight>?</h2>
    </div>
    <div class="my-8">
      <p>This form is to be used as receipt of the completion of your request.</p>
      <p class="mt-2">For any enquiries relating to fees and charges please call us on 1300 141 161.</p>
      <p class="mt-2">Once your request is received, we will contact you or your agent to progress your request.</p>
      <p class="mt-2">
        Disclaimer: Please note that Bridgit requires a minimum of 72 hours to prepare and settle a discharge. Any
        requests submitted with less than 72 hours notice may result in delays. 72 hours notice is also applicable for
        any changes to the dates of discharge.
      </p>
    </div>
    <div class="my-8">
      <BxCard>
        <h2 class="mb-4 text-xl font-medium">The loan will be repaid by way of</h2>
        <p class="mt-2">
          I/We would like to commence the discharge process to repay my/our loan account for the property(s) below:
        </p>
        <div v-for="(property, index) of properties" :key="property.repaidByWayOf + index">
          <PropertyForm
            v-model="properties[index]"
            :reference="properties"
            :index="index"
            @state="itemState"
            @remove="removeProperty(index)"
          />
        </div>
        <div v-if="type === 'submit'" class="mt-10 flex gap-4">
          <BxButton variant="tertiary" :disabled="disableAddNewPropertySold" @click="newProperty('property_sold')">
            <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Property sold
          </BxButton>
          <BxButton variant="tertiary" :disabled="disableAddNewPropertyRefinanced" @click="newProperty('refinance')">
            <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Property refinanced
          </BxButton>
          <BxButton variant="tertiary" :disabled="disableAddNewCashRepayment" @click="newProperty('cash_repayment')">
            <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Cash payment
          </BxButton>
        </div>
      </BxCard>
    </div>
    <BxErrorPanel v-show="errorMessage !== ''" class="mt-6">
      {{ errorMessage }}
    </BxErrorPanel>
    <div class="mt-12">
      <BxButton :disabled="properties.length === 0" variant="primary" @click="saveAndContinue()">{{
        type === 'submit' ? 'Save and continue' : 'Next'
      }}</BxButton>
    </div>
  </div>
</template>
