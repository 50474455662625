import type { operations } from '@/types/schemas/api.JS-3582';
import { POST, setParams } from '../../_http';
type controller = operations['BrokerTasksUploadSupportingDocumentsController_post'];

export type BrokersUploadSupportingDocumentsTaskQuery = controller['parameters']['query'];
export type BrokersUploadSupportingDocumentsTaskBody = controller['requestBody']['content']['application/json'];
export type BrokersUploadSupportingDocumentsTaskResponse =
  controller['responses']['200']['content']['application/json'];
export type UploadSupportingDocumentsFiles = controller['requestBody']['content']['application/json']['files'];
export type UploadSupportingDocumentsFileContentType =
  controller['requestBody']['content']['application/json']['files']['0']['contentType'];

export const uploadSupportingDocuments = POST(
  '/brokers/tasks/upload-supporting-documents',
  setParams<BrokersUploadSupportingDocumentsTaskQuery>,
)<BrokersUploadSupportingDocumentsTaskBody, BrokersUploadSupportingDocumentsTaskResponse>;
