<script setup lang="ts">
import { useDischargeRequestProcess } from '@/helpers/useDischargeRequestProcess';
import { noWhiteSpaceKeys, onlyValidPhoneKeys } from '@/helpers/noWhiteSpaceKeys';
import { computed, onMounted, ref } from 'vue';
import { useForm } from 'vee-validate';
import { solicitorSchema } from '@/validation';
import type { DischargeRequest } from '@/types/DischargeRequest';
import type { SubmitDischargeRequestSolicitorDto } from '@/api/backend/users/settlements';
import { mapFromApi } from '@/mappers';
import type { Address } from '@/types';

const { saveAndGoNext, goBack, loanApp, dischargeRequest, type } = useDischargeRequestProcess();

type Solicitor = SubmitDischargeRequestSolicitorDto & {
  address: Address | null;
};

const showEntry = ref(true);

const { values, meta, submitCount, handleSubmit, resetForm } = useForm<Solicitor>({
  validationSchema: solicitorSchema,
  validateOnMount: false,
});

const readonly = computed(() => {
  return type.value === 'review';
});

const next = () => {
  saveAndGoNext({
    dischargeRequest: { ...dischargeRequest.value } as DischargeRequest,
  });
};

const saveAndContinue = handleSubmit(
  (values) => {
    saveAndGoNext({
      dischargeRequest: {
        ...dischargeRequest.value,
        representativeDetails: {
          firstName: values.firstName ?? '',
          lastName: values.lastName ?? '',
          company: values.company ?? '',
          mobilePhoneNumber: values.mobilePhoneNumber ?? '',
          email: values.email ?? '',
          address: values.address,
        },
      } as DischargeRequest,
    });
    return true;
  },
  () => false,
);

onMounted(() => {
  if (!dischargeRequest.value?.representativeDetails) {
    const data = loanApp.value.solicitor ?? ({} as Solicitor);
    resetForm({ values: { ...data, address: data?.line1 ? mapFromApi.address(data) : null } });
  } else {
    resetForm({
      values: {
        ...dischargeRequest.value.representativeDetails,
      },
    });
  }
});
</script>

<template>
  <div>
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">Representative details</h2>
    </div>
    <div class="my-8">
      <p>
        Important information: Details relating to this release - you authorise us to deal directly with the person,
        firm, or company notes here below for the purposes of this release of security.
      </p>
    </div>
    <div class="my-8">
      <BxExpander v-model="showEntry" separator>
        <template #header>
          <div class="px-4 py-5 sm:px-6">
            <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Solicitor Firm/Financial institution/Conveyancer/Agent/Self-acting
              </h3>
            </div>
          </div>
        </template>
        <template #body>
          <div class="p-6">
            <BxField v-slot="{ field }" name="firstName">
              <BxInput
                :id="`representative-details-first-name`"
                class="form-control"
                v-bind="field"
                label="First name"
                label-type="floating"
                :readonly="readonly"
              />
            </BxField>
            <BxField v-slot="{ field }" name="lastName">
              <BxInput
                :id="`representative-details-last-name`"
                class="form-control"
                v-bind="field"
                label="Last name"
                label-type="floating"
                :readonly="readonly"
              />
            </BxField>
            <BxField v-slot="{ field }" name="company">
              <BxInput
                :id="`representative-details-company`"
                class="form-control"
                v-bind="field"
                label="Company"
                label-type="floating"
                :readonly="readonly"
              />
            </BxField>
            <BxField v-slot="{ field }" name="mobilePhoneNumber">
              <BxInput
                :id="`representative-details-mobile`"
                class="form-control"
                inputmode="tel"
                v-bind="field"
                label="Mobile number"
                label-type="floating"
                minlength="10"
                :maxlength="values.mobilePhoneNumber?.startsWith('+') ? 15 : 10"
                :readonly="readonly"
                @keypress="onlyValidPhoneKeys"
              />
            </BxField>
            <BxField v-slot="{ field }" name="email">
              <BxInput
                :id="`representative-details-email`"
                class="form-control"
                inputmode="email"
                v-bind="field"
                label="Email address"
                label-type="floating"
                :readonly="readonly"
                @keypress="noWhiteSpaceKeys"
              />
            </BxField>
            <BxField v-slot="{ field }" name="address">
              <BxAddressInput
                :id="`representative-details-address`"
                v-bind="field"
                label="Address"
                label-type="floating"
                manual-input-address-title="Address"
                class="form-control"
                :readonly="readonly"
              />
            </BxField>
          </div>
        </template>
      </BxExpander>
    </div>
    <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
      Please review the error messages above.
    </BxErrorPanel>
    <div class="mt-12">
      <BxButton variant="primary" @click="readonly ? next() : saveAndContinue()">{{
        readonly ? 'Next' : 'Save and continue'
      }}</BxButton>
      <BxButton variant="link" class="ml-4" @click="goBack()">Back</BxButton>
    </div>
  </div>
</template>
