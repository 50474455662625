import { CurrentKey } from '@/helpers/const';
import { formatAddress } from '@/helpers/format';
import type {
  Address,
  Applicant,
  ApplicantId,
  DischargeRequestProcessStepEnum,
  LoanApplication,
  PropertyId,
  DischargeRequestProcessStepStatus,
} from '@/types';
import { DischargeRequestStep, DischargeRequestStepNames, DischargeRequestProcessSteps, StepStatus } from '@/types';
import { useActor } from '@xstate/vue';
import { computed } from 'vue';
import { interpret } from 'xstate';
import {
  type DischargeRequestMachineContext,
  initDischargeRequestMachine,
  loanAppDischargeRequestMachine,
} from './loanDischargeRequestMachine';

const appKey = localStorage.getItem(CurrentKey) ?? '';
const savedState = JSON.parse(localStorage.getItem(appKey) as string);
const initialState: DischargeRequestMachineContext = savedState ? savedState : initDischargeRequestMachine();

const service = interpret(loanAppDischargeRequestMachine.withContext(initialState)).start();

service.onTransition((state) => {
  if (state.value !== 'gotoReady' && state.value !== 'start') {
    const currentStep = state.value as DischargeRequestProcessStepEnum;
    if (state.context.status[currentStep] === StepStatus.Incomplete) {
      state.context.status[currentStep] = StepStatus.Ready;
    }
    state.context.current = currentStep;
    const loanApp = state.context.loanApp;
    if (loanApp.applicationId && loanApp.userType) {
      const key = `${loanApp.actionType}-${loanApp.applicationId}`;
      const appState = JSON.stringify(state.context);
      localStorage.setItem(CurrentKey, key);
      localStorage.setItem(key, appState);
    }
  }
});

const processSteps = Object.entries(DischargeRequestProcessSteps).map(([step, info]) => ({
  id: step as DischargeRequestProcessStepEnum,
  ...info,
}));

export function useDischargeRequestProcess() {
  const { state, send } = useActor(service);
  const currentStep = computed(() => state.value.context.current);
  const loanApp = computed(() => state.value.context.loanApp);
  const authorisedUser = state.value.context.authorisedUser;
  const task = computed(() => state.value.context.task);
  const dischargeRequest = computed(() => state.value.context.loanApp.dischargeRequest);
  const type = computed(() => state.value.context.type);
  const originTask = computed(() => state.value.context.originTaskId);

  const stepper = computed<DischargeRequestProcessStepStatus[]>(() => {
    const currentStep = DischargeRequestStepNames.includes(state.value.context.current)
      ? state.value.context.current
      : DischargeRequestStepNames[0];
    return processSteps
      .filter(
        (step) =>
          loanApp.value.userType &&
          step.actions.includes(loanApp.value.actionType) &&
          step.id !== DischargeRequestStep.Finished,
      )
      .map((step) => {
        const stepStatus = state.value.context.status[step.id];
        const status =
          stepStatus === StepStatus.Incomplete &&
          DischargeRequestProcessSteps[step.id].order < DischargeRequestProcessSteps[currentStep].order
            ? StepStatus.Skipped
            : stepStatus;
        return { ...step, status };
      })
      .filter((step) => step.status !== StepStatus.Skipped);
  });

  const loanProperties = computed(() =>
    [...loanApp.value.incomingProperties, ...loanApp.value.outgoingProperties].reduce((obj, item) => {
      obj[item.id] = formatAddress(item.address as Address);
      return obj;
    }, {} as Record<PropertyId, string>),
  );
  const loanApplicants = computed<Record<string, Applicant>>(() =>
    loanApp.value.applicants.reduce((obj, item) => {
      obj[item.id as string] = item;
      return obj;
    }, {} as Record<ApplicantId, Applicant>),
  );

  const saveAndGoNext = (updatedData: Partial<LoanApplication> = {}) => {
    if (currentStep.value === DischargeRequestStep.SubmitDischargeRequest) {
      localStorage.setItem(CurrentKey, '');
    }
    send({ type: 'NEXT', loanApp: { ...state.value.context.loanApp, ...updatedData } });
  };

  const goBack = () => {
    send('BACK');
  };

  const restart = () => {
    send('RESTART');
  };

  return {
    state,
    service,
    stepper,
    currentStep,
    authorisedUser,
    loanApp,
    loanProperties,
    loanApplicants,
    task,
    dischargeRequest,
    type,
    originTask,
    send,
    saveAndGoNext,
    goBack,
    restart,
  };
}
