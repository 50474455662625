import type { operations } from '@/types/schemas/api.JS-3582';
import { GET, setParams } from '../../_http';
type controller = operations['BrokerRetrieveTaskController_get'];

export type BrokerRetrieveTaskQuery = controller['parameters']['query'];
export type BrokerRetrieveTaskResponse = controller['responses']['200']['content']['application/json'];

export const retrieveTask = GET(
  '/brokers/tasks/retrieve-task',
  setParams<BrokerRetrieveTaskQuery>,
)<BrokerRetrieveTaskResponse>;
