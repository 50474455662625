import axios from 'axios';

export const uploadFileToS3 = async (signedUrl: string, file: File, progressBar?: HTMLElement) => {
  if (signedUrl === 'string') {
    return true;
  }
  return await axios.put(signedUrl, file, {
    onUploadProgress: (progressEvent) => {
      if (progressBar) {
        progressBar.style.width = Math.round(progressEvent.loaded / file.size) * 100 + '%';
      }
    },
    headers: {
      'Content-Type': file.type,
    },
  });
};
