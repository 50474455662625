export const noWhiteSpaceKeys = (event: KeyboardEvent) => {
  if (event.key.match(/\s/)) {
    event.preventDefault();
  }
};

export const onlyValidPhoneKeys = (event: KeyboardEvent) => {
  if ((event.key < '0' || event.key > '9') && event.key !== '+') {
    event.preventDefault();
  }
};
