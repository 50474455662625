import type { operations } from '@/types/schemas/api.JS-3582';
import { GET, setParams } from '../../_http';
type controller = operations['CustomerRetrieveTaskController_get'];

export type CustomerRetrieveTaskQuery = controller['parameters']['query'];
export type CustomerRetrieveTaskResponse = controller['responses']['200']['content']['application/json'];
export type CustomerTaskResult = controller['responses']['200']['content']['application/json']['task'];

export const retrieveTask = GET(
  '/users/tasks/retrieve-task',
  setParams<CustomerRetrieveTaskQuery>,
)<CustomerRetrieveTaskResponse>;
