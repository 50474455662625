<script setup lang="ts">
import { updateToken } from '@/api/backend/_http';
import { toast } from '@/helpers/toast';
import { isLoanAppComplete } from '@/helpers/typeGuards';
import { useApplicationApi } from '@/helpers/useApplicationApi';
import { useDischargeRequestProcess } from '@/helpers/useDischargeRequestProcess';
import type { Applicant } from '@/types';
import { useForm } from 'vee-validate';
import { AppValues } from '@/helpers/const';
import { computed } from 'vue';
import { useDischargeRequestApi } from '@/helpers/useDischargeRequestApi';

const {
  loanApp,
  send,
  saveAndGoNext,
  goBack,
  authorisedUser,
  task,
  dischargeRequest,
  loanProperties,
  type,
  originTask,
} = useDischargeRequestProcess();
const { handleSubmit } = useForm({
  initialValues: { isRequired: loanApp.value.userType === 'Primary' },
  validateOnMount: false,
});

const fullName = computed(() => {
  return [authorisedUser.firstName, authorisedUser.lastName].filter(Boolean).join(' ');
});

const currentDate = computed(() => {
  return new Date().toISOString();
});

const readonly = computed(() => {
  return type.value === 'review';
});

const saveAndContinue = handleSubmit(() => {
  const applicants: Applicant[] = loanApp.value.applicants;
  send({
    type: 'UPDATE',
    loanApp: {
      ...loanApp.value,
      applicants,
    },
  });
  submitApplicantData();
});

const confirm = () => {
  const applicants: Applicant[] = loanApp.value.applicants;
  send({
    type: 'UPDATE',
    loanApp: {
      ...loanApp.value,
      applicants,
    },
  });
  signDischargeRequest();
};

const appApi = useApplicationApi();
const dischargeApi = useDischargeRequestApi();
const isLoading = dischargeApi.isLoading;

const signDischargeRequest = async () => {
  if (!originTask.value) {
    toast({
      type: 'danger',
      title: 'Error',
      description: `Could not submit your application. It looks like something is missing in the application. Please contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support.`,
    });
    return;
  }
  const result = await dischargeApi.signDischargeRequest({
    applicationId: loanApp.value.applicationId,
    taskId: originTask.value,
  });
  if (result.success) {
    toast({ type: 'success', title: 'Discharge request signed successfully' });
    updateToken(null);
    saveAndGoNext();
  } else {
    toast({ type: 'danger', title: 'Error', description: result.error });
  }
};

const submitApplicantData = async () => {
  if (isLoanAppComplete(loanApp.value)) {
    if (dischargeRequest.value) {
      const result = await dischargeApi.submitDischargeRequest({
        applicationId: loanApp.value.applicationId,
        loanApplication: loanApp.value,
        taskId: task.value?.id ?? '',
        dischargeRequest: dischargeRequest.value,
        loanProperties: loanProperties.value,
      });
      if (result.success) {
        toast({ type: 'success', title: 'Discharge request submitted successfully' });
        updateToken(null);
        saveAndGoNext();
      } else {
        toast({ type: 'danger', title: 'Error', description: result.error });
      }
    } else {
      toast({ type: 'danger', title: 'Error', description: 'Missing discharge request details' });
    }
  } else {
    toast({
      type: 'danger',
      title: 'Error',
      description: `Could not submit your application. It looks like something is missing in the application. Please contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support.`,
    });
  }
};

// This will backup applications created by the broker before submission.
// Broker backup is done here since brokers bypass the verification step.
if (loanApp.value.userType === 'Broker') {
  appApi.backupApplication(loanApp.value);
}
</script>

<template>
  <div>
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">Acknowledgement and authority</h2>
    </div>
    <div class="my-8">
      <p>I/We acknowledge the following conditions:</p>
      <ul class="ml-4 mt-2 list-disc">
        <li class="my-4">The loan repayments are due in full until the day discharge is complete;</li>
        <li class="my-4">
          That on settlement a release of Mortgage so far as the land is concerned will be given but I/we shall not be
          released from the personal covenants of the Mortgage until audit of the account is completed. Any necessary
          adjustments of a refund will be forwarded to me/us via post.
        </li>
        <li class="my-4">
          I/we similarly understand that if the audit shows further monies are due I/we shall be asked to pay them.
        </li>
        <li class="my-4">Bridgit's Solicitor requires five (5) days notice for booking of the discharge settlement.</li>
      </ul>
    </div>
    <div class="my-8">
      <BxInput :id="`submission-full-name`" class="form-control" readonly :model-value="fullName" />
      <BxDatePicker
        :id="`submission-date`"
        v-model="currentDate"
        label-type="floating"
        placeholder="DD/MM/YYYY"
        class="form-control"
        readonly
      />
    </div>
    <div class="my-8">
      Please click <strong>{{ readonly ? '"Confirm"' : '"Submit"' }}</strong> below to
      {{ readonly ? 'sign' : 'finalise' }} this request.
    </div>
    <div class="mt-12">
      <div v-if="dischargeApi.currentProgress && isLoading" class="my-8 text-sm text-bridgit-navy-50">
        <span>{{ dischargeApi.currentProgress.value }}</span>
      </div>
      <BxButton
        id="submission-submit"
        variant="primary"
        :loading="isLoading"
        @click="readonly ? confirm() : saveAndContinue()"
        >{{ readonly ? 'Confirm' : 'Submit application' }}</BxButton
      >
      <BxButton variant="link" class="ml-4" :disabled="isLoading" @click="goBack()">Back</BxButton>
    </div>
  </div>
</template>
