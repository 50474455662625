import ApplicationIcon from '@/components/icons/ApplicationIcon.vue';
import CircleTickIcon from '@/components/icons/CircleTickIcon.vue';
import CircleChevronLeftIcon from '@/components/icons/CircleChevronLeftIcon.vue';
import CircleChevronRightIcon from '@/components/icons/CircleChevronRightIcon.vue';
import CircleInfoIcon from '@/components/icons/CircleInfoIcon.vue';
import DocSearchIcon from '@/components/icons/DocSearchIcon.vue';
import PadlockIcon from '@/components/icons/PadlockIcon.vue';
import PhoneIcon from '@/components/icons/PhoneIcon.vue';
import StopwatchIcon from '@/components/icons/StopwatchIcon.vue';
import AcceptIcon from '@/components/icons/progress/AcceptIcon.vue';
import ApplicantIcon from '@/components/icons/progress/ApplicantIcon.vue';
import ExpenseIcon from '@/components/icons/progress/ExpenseIcon.vue';
import HouseInfoIcon from '@/components/icons/progress/HouseInfoIcon.vue';
import IncomeIcon from '@/components/icons/progress/IncomeIcon.vue';
import LiabilityIcon from '@/components/icons/progress/LiabilityIcon.vue';
import ProofIcon from '@/components/icons/progress/ProofIcon.vue';
import SellingIcon from '@/components/icons/progress/SellingIcon.vue';
import LoanIcon from '@/components/icons/progress/LoanIcon.vue';
import BridgitLogo from '@/components/icons/BridgitLogo.vue';
import BuyNowIcon from '@/components/icons/BuyNowIcon.vue';
import CashAdvanceIcon from '@/components/icons/CashAdvanceIcon.vue';
import ReportsIcon from '@/components/icons/ReportsIcon.vue';
import TickIcon from '@/components/icons/TickIcon.vue';
import CrossIcon from '@/components/icons/CrossIcon.vue';
import UploadIcon from '@/components/icons/UploadIcon.vue';
import DocumentIcon from '@/components/icons/DocumentIcon.vue';

export const icons = {
  accept: AcceptIcon,
  application: ApplicationIcon,
  applicant: ApplicantIcon,
  bridgit: BridgitLogo,
  buyNow: BuyNowIcon,
  cashAdvance: CashAdvanceIcon,
  circleTick: CircleTickIcon,
  circleChevronLeft: CircleChevronLeftIcon,
  circleChevronRight: CircleChevronRightIcon,
  circleInfo: CircleInfoIcon,
  docSearch: DocSearchIcon,
  document: DocumentIcon,
  expense: ExpenseIcon,
  houseInfo: HouseInfoIcon,
  income: IncomeIcon,
  loan: LoanIcon,
  liability: LiabilityIcon,
  padlock: PadlockIcon,
  phone: PhoneIcon,
  proof: ProofIcon,
  reports: ReportsIcon,
  selling: SellingIcon,
  stopwatch: StopwatchIcon,
  tick: TickIcon,
  cross: CrossIcon,
  upload: UploadIcon,
} as const;

export type IconName = keyof typeof icons;
