import ApplicantsStep from '@/views/customer-application/ApplicantsStep.vue';
import AssetsStep from '@/views/customer-application/AssetsStep.vue';
import ExpensesStep from '@/views/customer-application/ExpensesStep.vue';
import FinishedStep from '@/views/customer-application/FinishedStep.vue';
import IncomeStep from '@/views/customer-application/IncomeStep.vue';
import IncomingPropertyStep from '@/views/customer-application/IncomingPropertyStep.vue';
import LoanDetailsStep from '@/views/customer-application/LoanDetailsStep.vue';
import LoanSummaryStep from '@/views/customer-application/LoanSummaryStep.vue';
import LoanTypeStep from '@/views/customer-application/LoanTypeStep.vue';
import OutgoingPropertiesStep from '@/views/customer-application/OutgoingPropertiesStep.vue';
import PropertyOwnershipStep from '@/views/customer-application/PropertyOwnershipStep.vue';
import ReadyStep from '@/views/customer-application/ReadyStep.vue';
import SubmissionStep from '@/views/customer-application/SubmissionStep.vue';
import VerificationStep from '@/views/customer-application/VerificationStep.vue';
import type { RouteRecordRaw } from 'vue-router';

import BrokerApplicantStep from '@/views/broker-application/ApplicantsStep.vue';
import BrokerAssetsSteps from '@/views/broker-application/AssetsStep.vue';
import BrokerExpensesStep from '@/views/broker-application/ExpensesStep.vue';
import BrokerIncomeStep from '@/views/broker-application/IncomeStep.vue';
import BrokerIncomingPropertyStep from '@/views/broker-application/IncomingPropertyStep.vue';
import BrokerLoanDetailsStep from '@/views/broker-application/LoanDetailsStep.vue';
import BrokerLoanPurposeStep from '@/views/broker-application/LoanPurposeStep.vue';
import BrokerLoanTypeStep from '@/views/broker-application/LoanTypeStep.vue';
import BrokerOutgoingPropertyStep from '@/views/broker-application/OutgoingPropertiesStep.vue';
import BrokerPropertyOwnershipStep from '@/views/broker-application/PropertyOwnershipStep.vue';
import BrokerReadyStep from '@/views/broker-application/ReadyStep.vue';
import BrokerSubmissionStep from '@/views/broker-application/SubmissionStep.vue';

import DeclarationAssetsStep from '@/views/applicant-declaration/AssetsStep.vue';
import DeclarationExpensesStep from '@/views/applicant-declaration/ExpensesStep.vue';
import DeclarationIncomeStep from '@/views/applicant-declaration/IncomeStep.vue';
import DeclarationLoanSummaryStep from '@/views/applicant-declaration/LoanSummaryStep.vue';
import DeclarationReadyStep from '@/views/applicant-declaration/ReadyStep.vue';
import DeclarationSubmissionStep from '@/views/applicant-declaration/SubmissionStep.vue';
import DeclarationVerificationStep from '@/views/applicant-declaration/VerificationStep.vue';

import RequestAssetsStep from '@/views/income-expense-request/AssetsStep.vue';
import RequestExpensesStep from '@/views/income-expense-request/ExpensesStep.vue';
import RequestIncomeStep from '@/views/income-expense-request/IncomeStep.vue';
import RequestLoanSummaryStep from '@/views/income-expense-request/LoanSummaryStep.vue';
import RequestReadyStep from '@/views/income-expense-request/ReadyStep.vue';
import RequestSubmissionStep from '@/views/income-expense-request/SubmissionStep.vue';

import DischargeRequestReadyStep from '@/views/discharge-request/ReadyStep.vue';
import DischargeRequestStep from '@/views/discharge-request/DischargeRequest.vue';
import DischargeRequestRepresentativeDetailsStep from '@/views/discharge-request/RepresentativeDetails.vue';
import DischargeRequestSubmissionStep from '@/views/discharge-request/SubmissionStep.vue';

export const loanApplicationRoutes: RouteRecordRaw[] = [
  {
    path: '/application',
    meta: {
      layout: 'CustomerApplicationLayout',
      title: 'Customer | Loan Application',
    },
    children: [
      {
        path: '',
        name: 'application',
        component: ReadyStep,
      },
      {
        path: 'loan-type',
        name: 'selectLoanType',
        component: LoanTypeStep,
      },
      {
        path: 'buying/loan-purpose',
        name: 'incomingProperties',
        component: IncomingPropertyStep,
      },
      {
        path: 'selling/property',
        name: 'outgoingProperties',
        component: OutgoingPropertiesStep,
      },
      {
        path: 'loan-details/additional-funds',
        name: 'loanDetails',
        component: LoanDetailsStep,
      },
      {
        path: 'applicant/details',
        name: 'applicantDetails',
        component: ApplicantsStep,
      },
      {
        path: 'property-ownership',
        name: 'propertyOwnership',
        component: PropertyOwnershipStep,
      },
      {
        path: 'summary',
        name: 'summary',
        component: LoanSummaryStep,
      },
      {
        path: 'income/source',
        name: 'income',
        component: IncomeStep,
      },
      {
        path: 'expenses/dependant',
        name: 'expenses',
        component: ExpensesStep,
      },
      {
        path: 'assets-liability/assets',
        name: 'assets',
        component: AssetsStep,
      },
      {
        path: 'verify/mobile-number',
        name: 'verification',
        component: VerificationStep,
      },
      {
        path: 'declaration/declaration-form',
        name: 'submitApplication',
        component: SubmissionStep,
      },
    ],
  },
  {
    path: '/broker-application',
    meta: {
      layout: 'BrokerApplicationLayout',
      title: 'Broker | Loan Application',
    },
    children: [
      {
        path: '',
        name: 'broker',
        component: BrokerReadyStep,
      },
      {
        path: 'loan-type',
        name: 'brokerSelectLoanType',
        component: BrokerLoanTypeStep,
      },
      {
        path: 'loan-purpose',
        name: 'brokerLoanPurpose',
        component: BrokerLoanPurposeStep,
      },
      {
        path: 'buying/loan-purpose',
        name: 'brokerIncomingProperties',
        component: BrokerIncomingPropertyStep,
      },
      {
        path: 'selling/property',
        name: 'brokerOutgoingProperties',
        component: BrokerOutgoingPropertyStep,
      },
      {
        path: 'loan-details/additional-funds',
        name: 'brokerLoanDetails',
        component: BrokerLoanDetailsStep,
      },
      {
        path: 'applicant/details',
        name: 'brokerApplicantDetails',
        component: BrokerApplicantStep,
      },
      {
        path: 'property-ownership',
        name: 'brokerPropertyOwnership',
        component: BrokerPropertyOwnershipStep,
      },
      {
        path: 'income/source',
        name: 'brokerIncome',
        component: BrokerIncomeStep,
      },
      {
        path: 'expenses/dependant',
        name: 'brokerExpenses',
        component: BrokerExpensesStep,
      },
      {
        path: 'assets-liability/assets',
        name: 'brokerAssets',
        component: BrokerAssetsSteps,
      },
      {
        path: 'declaration/declaration-form',
        name: 'brokerSubmitApplication',
        component: BrokerSubmissionStep,
      },
    ],
  },
  {
    path: '/declaration',
    meta: {
      layout: 'DeclarationLayout',
      title: 'Declaration | Loan Application',
    },
    children: [
      {
        path: '',
        name: 'declaration',
        component: DeclarationReadyStep,
      },
      {
        path: 'summary',
        name: 'declarationSummary',
        component: DeclarationLoanSummaryStep,
      },
      {
        path: 'income/source',
        name: 'declarationIncome',
        component: DeclarationIncomeStep,
      },
      {
        path: 'expenses/dependant',
        name: 'declarationExpenses',
        component: DeclarationExpensesStep,
      },
      {
        path: 'assets-liability/assets',
        name: 'declarationAssets',
        component: DeclarationAssetsStep,
      },
      {
        path: 'verify/mobile-number',
        name: 'declarationVerification',
        component: DeclarationVerificationStep,
      },
      {
        path: 'declaration/declaration-form',
        name: 'declarationSubmitApplication',
        component: DeclarationSubmissionStep,
      },
    ],
  },
  {
    path: '/discharge',
    meta: {
      layout: 'DischargeRequestLayout',
      title: 'Submit Discharge Request',
    },
    children: [
      {
        path: '',
        name: 'discharge',
        component: DischargeRequestReadyStep,
      },
      {
        path: 'request',
        name: 'dischargeRequest',
        component: DischargeRequestStep,
      },
      {
        path: 'request/representative-details',
        name: 'representativeDetails',
        component: DischargeRequestRepresentativeDetailsStep,
      },
      {
        path: 'request/discharge-request-form',
        name: 'submitDischargeRequest',
        component: DischargeRequestSubmissionStep,
      },
    ],
  },
  {
    path: '/income-expense',
    meta: {
      layout: 'RequestIncomeExpenseLayout',
      title: 'Loan Application',
    },
    children: [
      {
        path: '',
        name: 'request',
        component: RequestReadyStep,
      },
      {
        path: 'summary',
        name: 'requestSummary',
        component: RequestLoanSummaryStep,
      },
      {
        path: 'income/source',
        name: 'requestIncome',
        component: RequestIncomeStep,
      },
      {
        path: 'expenses/dependant',
        name: 'requestExpenses',
        component: RequestExpensesStep,
      },
      {
        path: 'assets-liability/assets',
        name: 'requestAssets',
        component: RequestAssetsStep,
      },
      {
        path: 'declaration/declaration-form',
        name: 'requestSubmitApplication',
        component: RequestSubmissionStep,
      },
    ],
  },
  {
    // /confirmation/review/user_declaration
    // /confirmation/review/income_expense
    // /confirmation/review/equity_release
    // /confirmation/review/bridge_loan
    // /confirmation/review/broker
    path: '/confirmation/review/:finishType',
    name: 'completed',
    component: FinishedStep,
    meta: {
      layout: 'CustomerApplicationLayout',
      title: 'Loan Application',
    },
  },
];
