<script setup lang="ts">
import type { UploadDocument } from '@/types';
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: File[]; // Pending for upload
    uploadedDocuments: UploadDocument[]; // For viewing uploaded documents
    id: string;
    label?: string;
    class?: string | string[] | object;
    readonly?: boolean;
  }>(),
  {
    label: undefined,
    class: undefined,
    readonly: false,
  },
);

const fileV = ref();
const fileUploadList = ref<File[]>([]);
const fileKey = ref(0);
const documents = ref<UploadDocument[]>([]);

const emit = defineEmits<{
  (e: 'update:modelValue', v: File[]): void;
  (e: 'uploadedDocumentsUpdated', v: UploadDocument[]): void;
  (e: 'removeUploadedDocument', v: UploadDocument): void;
  (e: 'fileClicked', v: UploadDocument): void;
}>();

watch(
  () => props.modelValue,
  (files) => {
    fileUploadList.value = files;
  },
  { deep: true, immediate: true },
);

watch(
  () => fileUploadList.value,
  (files) => {
    emit('update:modelValue', files);
  },
  { deep: true },
);

watch(
  () => props.uploadedDocuments,
  (values) => {
    documents.value = values;
  },
  { deep: true, immediate: true },
);

watch(
  () => documents.value,
  (values) => {
    emit('uploadedDocumentsUpdated', values);
  },
  { deep: true },
);

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
  if (!event.currentTarget) {
    return;
  }
  const classList = (event.currentTarget as HTMLElement).classList;
  if (!classList.contains('bg-green-300')) {
    classList.add('bg-green-300');
  }
};

const onDragLeave = (event: DragEvent) => {
  if (!event.currentTarget) {
    return;
  }
  const classList = (event.currentTarget as HTMLElement).classList;
  classList.remove('bg-green-300');
};

const onDrop = (event: DragEvent) => {
  event.preventDefault();
  if (event.dataTransfer) {
    fileV.value.files = event.dataTransfer.files;
    onChange();
  }
  if (event.currentTarget) {
    const classList = (event.currentTarget as HTMLElement).classList;
    classList.remove('bg-green-300');
  }
};

const onChange = () => {
  for (const file of fileV.value.files) {
    fileUploadList.value.push(file);
  }
};

const formatFileSize = (totalBytes: number) => {
  if (totalBytes < 1_000_000) {
    return (totalBytes / 1000).toFixed(1) + 'KB';
  } else {
    return (totalBytes / 1_000_000).toFixed(1) + 'MB';
  }
};

const removePendingUpload = (file: File) => {
  const index = fileUploadList.value.indexOf(file);
  if (index > -1) {
    fileUploadList.value.splice(index, 1);
    fileKey.value += 1;
  }
};

const removeUploadedFile = async (document: UploadDocument) => {
  const index = documents.value.findIndex((item) => item.id === document.id);
  if (index > -1) {
    documents.value.splice(index, 1);
    emit('removeUploadedDocument', document);
  }
};

const formatFileName = (fileName: string) => {
  const fileOnly = fileName.split('/').pop();
  return fileOnly;
};

const fileClicked = (document: UploadDocument) => {
  emit('fileClicked', document);
};
</script>

<template>
  <div :class="props.class">
    <div v-if="props.label" class="pb-2 text-sm font-semibold">{{ props.label }}</div>
    <div v-if="!props.readonly" class="file" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDrop">
      <input
        :id="props.id"
        ref="fileV"
        :key="fileKey"
        type="file"
        multiple
        name="fields[assetsFieldHandle][]"
        class="absolute h-px w-px overflow-hidden opacity-0"
        accept=".pdf,.jpg,.jpeg,.png"
        @change="onChange"
      />
      <label :for="props.id" class="upload-box">
        <div class="icon-text flex justify-center">
          <BxIcon name="upload" class="h-6 w-6" />
          <p class="text">Drag and drop files here or <span>browse</span></p>
        </div>
      </label>
    </div>
    <ul v-if="fileUploadList.length || documents.length" v-cloak class="file-list mt-4">
      <li v-for="(file, i) of fileUploadList" :key="i" class="flex items-center justify-center p-1 text-sm">
        <div class="file-container flex items-center justify-center">
          <BxIcon name="tick" class="h-7 w-7" />
          <div class="file-details">
            <div :id="`progress-${i}`" class="progress-bar"></div>
            <p class="file-name">{{ file.name }}</p>
            <div v-if="file.size" class="flex">
              <p class="text-sm text-gray-400">
                <span v-if="file.size > 1_000_000_000" class="text-red-500">Exceeds maximum file size 1 GB</span>
                <span v-else>Size: {{ formatFileSize(file.size) }}</span>
              </p>
            </div>
          </div>
        </div>
        <BxIcon name="cross" class="h-6 w-6 cursor-pointer" @click="removePendingUpload(file)" />
      </li>
      <li v-for="document of documents" :key="document.id" class="flex items-center justify-center p-1 text-sm">
        <div class="file-container flex items-center justify-center">
          <BxIcon :name="props.readonly ? 'document' : 'tick'" class="h-7 w-7" />
          <div class="file-details cursor-pointer" @click="fileClicked(document)">
            <p class="file-name">{{ formatFileName(document.s3FilePath) }}</p>
          </div>
        </div>
        <BxIcon
          v-if="!props.readonly"
          name="cross"
          class="h-6 w-6 cursor-pointer"
          @click="removeUploadedFile(document)"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.file-list {
  width: 100%;

  h2 {
    font-size: 1rem;
  }

  li {
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid #0200384d;

    .progress-bar {
      height: 0.25rem;
      line-height: 0.25rem;
      display: block;
      width: 0;
      background-color: #2cdd80;
    }

    .file-details {
      flex-grow: 1;
    }

    .file-container {
      flex-grow: 1;
      gap: 1rem;
      padding: 1rem 0;
      align-items: center;
    }

    .file-name {
      margin: 0;
      font-size: 0.875rem;
    }

    .icon {
      cursor: pointer;
    }
  }

  li:last-of-type {
    border-bottom: none;

    div {
      padding-bottom: 0;
    }
  }
}

.file {
  margin: 0rem 0 1rem 0;

  .icon-text {
    gap: 1rem;

    .text {
      margin: 0;
      font-size: 0.875rem;

      span {
        color: #2cdd80;
      }
    }
  }
}

.upload-box {
  width: 100%;
  border: 2px dashed #54b7f9;
  background: #54b7f91a;
  border-radius: 5px;
  display: block;
  padding: 1rem;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
}

.bg-green-300 {
  background-color: #ffffff !important;
}
</style>
